import { basicPasswordOptions } from "constants/basicFormOptions";

export const forgotPasswordDefaultInputs = {
  username: "",
}

export const forgotPasswordInputs = [
  {
    name: "username",
    label: "Benutzername",
    placeholder: "Benutzername",
    options: {
      required: "Bitte geben Sie Ihren Benutzernamen ein.",
      pattern: {
        value: /^(?!\d+$)[\wäöüÄÖÜß\d.\-_+@]+$/, // NOSONAR
        message: "Ihr Benutzername enthält ungültige Zeichen.",
      },
    },
  }
];

export const resetPasswordDefaultValues = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

export const resetPasswordPublicInputs = [
  {
    type: "password",
    name: "newPassword",
    label: "Neues Passwort",
    placeholder: "Neues Passwort eingeben",
    autocomplete: "new-password",
    mandatory: true,
    strengthBar: true,
    options: basicPasswordOptions,
  },
  {
    type: "password",
    name: "confirmNewPassword",
    label: "Neues Passwort wiederholen",
    placeholder: "Neues Passwort wiederholen",
    autocomplete: "new-password",
    mandatory: true,
    options: {
      required: "Bitte wiederholen Sie Ihr neues Passwort.",
      validate: watch => val => {
        if (watch("newPassword") !== val) {
          return "Ihre beiden Passwörter stimmen nicht überein.";
        }
      }},
  },
];

export const resetPasswordUserInputs = [
  {
    type: "password",
    name: "currentPassword",
    label: "Aktuelles Passwort",
    placeholder: "Aktuelles Passwort eingeben",
    autocomplete: "current-password",
    mandatory: true,
  },
  {
    type: "password",
    name: "newPassword",
    label: "Neues Passwort",
    placeholder: "Neues Passwort eingeben",
    autocomplete: "new-password",
    mandatory: true,
    strengthBar: true,
    options: basicPasswordOptions,
  },
  {
    type: "password",
    name: "confirmNewPassword",
    label: "Neues Passwort wiederholen",
    placeholder: "Neues Passwort wiederholen",
    autocomplete: "new-password",
    mandatory: true,
    options: {
      required: "Bitte wiederholen Sie Ihr neues Passwort.",
      validate: watch => val => {
        if (watch("newPassword") !== val) {
          return "Ihre beiden Passwörter stimmen nicht überein.";
        }
      }},
  },
];
