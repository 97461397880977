import {
  routeAgbAccept,
  routeAccountSettings,
  routeRegistration,
  routeIpRegistrationSuccess,
  routeRegistrationOptinConfirm,
  routeRegistrationSuccess,
  routeSelfEnrollment,
  routeForgotUsername,
  routeForgotPassword,
  routeResetPassword,
  routeResetPasswordSuccessful,
  routeLogout,
  routeRegistrationOptinResend,
  routeRedirection,
} from "./path.routing";
import Registration from "../pages/registration/Registration";
import { ForgotUsername } from "../pages/forgotUsername/ForgotUsername";
import { ForgotPassword } from "../pages/forgotPassword/ForgotPassword";
import { ChangePassword } from "../pages/forgotPassword/ui/ChangePassword";
import { ChangePasswordSuccessful } from "../pages/forgotPassword/ui/ChangePasswordSuccessful";
import { IpRegistrationSuccessful } from "../pages/registration/ui/IpRegistrationSuccessful";
import { BasicRegistrationSuccessful } from "../pages/registration/ui/BasicRegistrationSuccessful";
import { OptinConfirmation } from "../pages/registration/ui/OptinConfirmation";
import SelfEnrollment from "../pages/selfEnrollment/SelfEnrollment";
import { Logout } from "../pages/staticPages/Logout";
import { Redirection } from "../pages/staticPages/Redirection";
import { AgbAccept } from "../pages/agbAccept/AgbAccept";
import { OptinResend } from "../pages/registration/ui/OptinResend";
import { AccountSettings } from "pages/accountSettings";

export const hiddenNavigation = [
  {
    element: <AgbAccept />,
    label: "AGB akzeptieren",
    path: routeAgbAccept,
    exact: true,
  },
  {
    element: <AccountSettings/>,
    label: "Kontoeinstellungen",
    path: routeAccountSettings,
    exact: true,
  },
  {
    element: <Registration />,
    label: "Registrierung",
    path: routeRegistration,
    exact: true,
  },
  {
    element: <SelfEnrollment />,
    label: "SelfEnrollment",
    path: routeSelfEnrollment,
    exact: true,
  },
  {
    element: <ForgotUsername />,
    label: "Benutzername vergessen",
    path: routeForgotUsername,
    exact: true,
  },
  {
    element: <ForgotPassword />,
    label: "Passwort vergessen",
    path: routeForgotPassword,
    exact: true,
  },
  {
    element: <ChangePassword />,
    label: "Passwort zurücksetzen",
    path: routeResetPassword,
    exact: true,
  },
  {
    element: <ChangePasswordSuccessful />,
    label: "Passwort geändert",
    path: routeResetPasswordSuccessful,
    exact: true,
  },
  {
    element: <IpRegistrationSuccessful />,
    label: "IpRegistrationSuccessful",
    path: routeIpRegistrationSuccess,
    exact: true,
  },
  {
    element: <BasicRegistrationSuccessful />,
    label: "RegistrationSuccessful",
    path: routeRegistrationSuccess,
    exact: true,
  },
  {
    element: <OptinConfirmation />,
    label: "OptinConfirmation",
    path: routeRegistrationOptinConfirm,
    exact: true,
  },
  {
    element: <OptinResend />,
    label: "OptinResend",
    path: routeRegistrationOptinResend,
    exact: true,
  },
  {
    element: <Redirection />,
    label: "Weiterleitung",
    path: routeRedirection,
    exact: true,
  },
  {
    element: <Logout />,
    label: "Logout",
    path: routeLogout,
    exact: true,
  }
];
