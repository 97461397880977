import { createAsyncThunk } from "@reduxjs/toolkit";
import { dataFetched, startFetching } from "./userSlice";
import { userApi } from "./userApi";

export const getAddress = createAsyncThunk(
  "user/getAddress",
  async (type, thunkApi) => {
    thunkApi.dispatch(startFetching());
    const res = await thunkApi.dispatch(
      userApi.endpoints.getAddress.initiate(type, {forceRefetch: true})
    );
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const getPersonalData = createAsyncThunk(
  "user/getPersonalData",
  async (username, thunkApi) => {
    thunkApi.dispatch(startFetching());
    const res = await thunkApi.dispatch(
      userApi.endpoints.getPersonalData.initiate({forceRefetch: true})
    );
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const getPreselectedSearchSettings = createAsyncThunk(
  "user/getPreselectedSearchSettings",
  async (username, thunkApi) => {
    thunkApi.dispatch(startFetching());
    const res = await thunkApi.dispatch(
      userApi.endpoints.getPreselectedSearchSettings.initiate()
    );
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);
