import React from "react";
import { useSelector } from "react-redux";
import NavItem from "../nav/NavItem";
import {
  backToProduct,
  mainNavigation,
} from "../../constants/navigation.constants";
import { authSelector } from "../../services/auth/authSlice";

const NavList = (props) => {
  const { isAuthenticated } = useSelector(authSelector);
  const backButton = backToProduct.map((item, index) => <NavItem
    {...props}
    key={index}
    label={" Zurück zu".concat(sessionStorage.getItem("btpLabel") === "" ? "m Produkt" : sessionStorage.getItem("btpLabel"))}
    href={sessionStorage.getItem("btpLink")}
    icon={item.icon}
  />);
  const basicNavigation = mainNavigation.map((item, index) => <NavItem
    {...props}
    key={index}
    label={item.label}
    href={item.path}
    icon={item.icon}
  />);
  let loggedIn;
  if (isAuthenticated) {
    loggedIn = basicNavigation;
  }

  return (
    <ul className={props.ulClass}>
      {// implement if-else when redirect to product is set
        backButton
      }
      { isAuthenticated &&
        <>
          {basicNavigation}
        </>
      }
    </ul>
  );
}

export default NavList;
