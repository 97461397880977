import React, { useEffect } from "react";
import { Iconsvg } from "@wk/components-react16";

export const Redirection = () => {

  useEffect(() => {
    window.location.href = sessionStorage.getItem("btpLink");
  }, []);

  return (
    <>
      <div style={{textAlign: "center"}}>
        <Iconsvg name="spinner" isSpin size={32}/>
      </div>
    </>
  );
}
