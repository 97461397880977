import React from "react";
import { useDispatch } from "react-redux";
import { ButtonField, Iconsvg } from "@wk/components-react16";
import { authApi } from "../../../services/auth/authApi";

/** Login Button for IP Users who want to login with their named user */
export const NamedLoginButton = (props) => {
  const dispatch = useDispatch();

  const doLogin = async () => {
    const { data, isSuccess } = await dispatch(
      authApi.endpoints.getReloginUrl.initiate({}, { forceRefetch: true })
    );
    if (isSuccess) {
      window.location.href = data.url;
    }
  };

  return (
    <ButtonField mode="text" iconPosition="left">
      <button id="wk-csa-ip-login" onClick={doLogin}>
        <Iconsvg name="login" />
        Persönlich anmelden
      </button>
    </ButtonField>
  );
};

export default NamedLoginButton;
