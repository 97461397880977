import { Stepthrough, StepItem } from "@wk/components-react16";

const StepThrough = ({ activeIndex, steps, children }) => {
  return (
    <div style={{ paddingTop: "1rem" }}>
      <Stepthrough activeIndex={activeIndex} mode="compact">
        {steps?.map((step, index) => (
          <StepItem
            key={index}
            slot="stepItem"
          >
            {step.stepTitle}
          </StepItem>
        ))}
      </Stepthrough>
      <>{children}</>
    </div>
  );
};

export default StepThrough;
