import { createSlice } from "@reduxjs/toolkit";
import { getEnvConfig, getCustomerData, getRegistrationFields, getWKORegistrationFields } from "./csaThunk";

const sliceName = "csaSlice";

const initialState = {
  config: {},
  error: {},
  isFetching: false,
  isSuccess: false,
  isError: false,
  isLoading: false,
  registrationFields: null,
};

export const csaSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearState: () => initialState,
    startFetching: (state) => {
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
    },
    dataFetched: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    errorOccurred: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = true;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEnvConfig.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getEnvConfig.fulfilled, (state, action) => {
      state.config = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getCustomerData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomerData.fulfilled, (state, action) => {
      state.config = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getRegistrationFields.fulfilled, (state, action) => {
      state.isLoading = false;
      state.registrationFields = action.payload.fields;
    });
  },
});

// Selector
export const csaSelector = (state) => state.csa;

const { actions, reducer } = csaSlice;

// Actions
export const { clearState, startFetching, dataFetched, errorOccurred } =
  actions;

// Reducer
export default reducer;
