import React, { useEffect, useState } from "react";
import { PasswordField } from "@wk/components-react16";
import PasswordStrengthBar from "components/form/PasswordStrengthBar";

export const Fields = ({
  formManager,
  inputs,
  setSubmitDisabled,
  setErrorNotification,
}) => {
  const { register, setValue, trigger, watch, formState: { isSubmitted, errors } } = formManager;
  const [passwordType, setPasswordType] = useState({});
  const [passwordValue, setPasswordValue] = useState({});

  useEffect(() => {
    Object.keys(passwordValue).forEach((fieldName) => {
      setValue(fieldName, passwordValue[fieldName]);
      if (isSubmitted) {
        trigger(fieldName);
      }
    });
    if (!Object.values(passwordValue).includes("")) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [passwordValue]);

  useEffect(() => {
    if (!Object.keys(errors).length) {
      setErrorNotification(false);
    } else {
      passwordValue["confirmNewPassword"] = "";
    }
  }, [Object.keys(errors).length]);

  const validationError = fieldName => errors[fieldName];

  const getIndicator = (fieldMandatory) => {
    if (fieldMandatory === true) {
      return "required";
    } else if (fieldMandatory === false) {
      return "optional";
    }
    return "none";
  }

  const getStatus = (fieldName) => {
    if (isSubmitted) {
      if (validationError(fieldName)) {
        return "error";
      } else {
        return "success";
      }
    }
    return "none";
  }

  const getError = (fieldName) => {
    return [
      {
        label: errors[fieldName].message,
        labelId: `${fieldName}-error-${errors[fieldName].type}`,
      },
    ];
  };

  const preparePasswordOptions = options => {
    if (options?.validate) {
      return {
        ...options,
        validate: options.validate(watch),
      };
    }
    return options;
  };

  const createPasswordKey = (fieldName) => {
    if (!passwordType.hasOwnProperty(fieldName)) {
      setPasswordType({
        ...passwordType,
        [fieldName]: "password"
      });
    }
    if (!passwordValue.hasOwnProperty(fieldName)) {
      setPasswordValue({
        ...passwordValue,
        [fieldName]: formManager.control._formValues[fieldName]
      });
    }
  }

  const changePasswordMode = (fieldName) => {
    if (passwordType[fieldName] === "password") {
      setPasswordType({
        ...passwordType,
        [fieldName]: "text"
      });
    } else {
      setPasswordType({
        ...passwordType,
        [fieldName]: "password"
      });
    }
  }

  const fields = inputs.map(field => {
    createPasswordKey(field.name);
    return <PasswordField
      key={field.name}
      label={field.label}
      labelFor={field.name}
      type={passwordType[field.name]}
      indicator={getIndicator(field.mandatory)}
      status={field.options && getStatus(field.name)}
      errors={
        isSubmitted && validationError(field.name)
          ? getError(field.name)
          : []
      }
      i18n={{
        revealPasswordText: "Passwort anzeigen",
        hidePasswordText: "Passwort verbergen"
      }}
      onModeChange={(event) => changePasswordMode(field.name, event)}>
      <input
        {...register(field.name, preparePasswordOptions(field.options))}
        type={passwordType[field.name]}
        id={field.name}
        name={field.name}
        placeholder={field.placeholder}
        value={passwordValue[field.name]}
        autoComplete={field.autocomplete}
        onChange={(event: any) => {
          setPasswordValue({
            ...passwordValue,
            [field.name]: event.target.value
          });
        }}
      />
      { field.strengthBar === true &&
        <PasswordStrengthBar checkValue={passwordValue[field.name]}/>
      }
    </PasswordField>
  });

  return (
    <>
      {fields}
    </>
  );
}

export default Fields;
