import { createAsyncThunk } from "@reduxjs/toolkit";
import { dataFetched, startFetching } from "./csaSlice";
import { csaApi } from "./csaApi";

export const getEnvConfig = createAsyncThunk(
  "csa/getEnvConfig",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    const res = await thunkApi.dispatch(
      csaApi.endpoints.getEnvConfig.initiate(data)
    );
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const getCustomerData = createAsyncThunk(
  "csa/getCustomerData",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    const res = await thunkApi.dispatch(
      csaApi.endpoints.getCustomerData.initiate(data)
    );
    if (res.isError) {
      if (res.error.status === 404) {
        return res.error.status;
      }
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const getSession = createAsyncThunk(
  "csa/getSession",
  async (_, thunkApi) => {
    thunkApi.dispatch(startFetching());
    const res = await thunkApi.dispatch(csaApi.endpoints.getSession.initiate());
    if (res.isError) {
      if (res.error.status === 404) {
        return res.error.status;
      }
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const getRegistrationFields = createAsyncThunk(
  "csa/getRegistrationFields",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    const res = await thunkApi.dispatch(
      csaApi.endpoints.getRegistrationFields.initiate(
        data,
        { forceRefetch: true }
      )
    );
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);
