import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { clientConfig } from "../constants/config";

const useProductInfo = () => {
  const dispatch = useDispatch();
  const [queryParameters, setQueryParameters] = useSearchParams();

  useEffect(() => {
    // set default config
    if (!sessionStorage.getItem("clientId")) {
      sessionStorage.setItem("clientId", "JurionClient");
    }
    if (!sessionStorage.getItem("btpLabel")) {
      sessionStorage.setItem("btpLabel", ` ${process.env.REACT_APP_CLIENT_LABEL}`);
    }
    if (!sessionStorage.getItem("btpLink")) {
      sessionStorage.setItem("btpLink", process.env.REACT_APP_START.concat("?refreshFlags=true"));
    }
    // overwrite default config if clientId exists in url
    if (queryParameters.has("clientId")) {
      sessionStorage.setItem("clientId", queryParameters.get("clientId"));
      queryParameters.delete("clientId");
      setQueryParameters(queryParameters);
      if (clientConfig.hasOwnProperty(sessionStorage.getItem("clientId"))) {
        const config = clientConfig[sessionStorage.getItem("clientId")];
        const envConfig = config[process.env.REACT_APP_ENV];
        sessionStorage.setItem("btpLabel", config.btpLabel);
        sessionStorage.setItem("btpLink", envConfig.start);
      } else {
        sessionStorage.setItem("btpLabel", ` ${process.env.REACT_APP_CLIENT_LABEL}`);
        sessionStorage.setItem("btpLink", process.env.REACT_APP_START.concat("?refreshFlags=true"));
      }
    }
    // overwrite config with custom values
    if (queryParameters.has("btpLink")) {
      sessionStorage.setItem("btpLink", queryParameters.get("btpLink"));
      queryParameters.delete("btpLink");
      setQueryParameters(queryParameters);
      if (queryParameters.has("btpLabel")) {
        sessionStorage.setItem("btpLabel", queryParameters.get("btpLabel"));
        queryParameters.delete("btpLabel");
        setQueryParameters(queryParameters);
      }
    }
  }, [dispatch]);
};

export default useProductInfo;
