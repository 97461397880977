import { createAsyncThunk } from "@reduxjs/toolkit";
import { expiredTokenDetected } from "helpers/token";
import { getToken } from "services/auth/authThunk";
import { dataFetched, startFetching } from "./accountSlice";
import { accountApi } from "./accountApi";

export const resendUsername = createAsyncThunk(
  "account/resendUsername",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(accountApi.endpoints.resendUsername.initiate(data));
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const sendResetPasswordLink = createAsyncThunk(
  "account/sendResetPasswordLink",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(accountApi.endpoints.sendResetPasswordLink.initiate(data));
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const updatePublicPassword = createAsyncThunk(
  "account/updatePassword",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(accountApi.endpoints.updatePublicPassword.initiate(data));
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const createUserAccount = createAsyncThunk(
  "account/createUserAccount",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(accountApi.endpoints.createUserAccount.initiate(data));
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  },
);

export const createSubuserAccount = createAsyncThunk(
  "account/createSubuserAccount",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(accountApi.endpoints.createSubuserAccount.initiate(data));
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  },
);

export const createWkoAccount = createAsyncThunk(
  "account/createWkoAccount",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(accountApi.endpoints.createWkoAccount.initiate(data));
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  },
);

export const importUserAccount = createAsyncThunk(
  "account/importUserAccount",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(accountApi.endpoints.importUserAccount.initiate(data));
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  },
);

export const importSubuserAccount = createAsyncThunk(
  "account/importSubuserAccount",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(accountApi.endpoints.importSubuserAccount.initiate(data));
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  },
);

export const resendOptinEmail = createAsyncThunk(
  "account/resendOptinEmail",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(accountApi.endpoints.resendOptinEmail.initiate(data));
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const updateConsent = createAsyncThunk(
  "account/updateFieldConsent",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(
      accountApi.endpoints.updateFieldConsent.initiate(data)
    );
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const updateOccupationSpheres = createAsyncThunk(
  "account/updateFieldOccupationSpheres",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(
      accountApi.endpoints.updateFieldOccupationSpheres.initiate(data)
    );
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const updatePassword = createAsyncThunk(
  "account/updateFieldPassword",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(
      accountApi.endpoints.updateFieldPassword.initiate(data)
    );
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const updateTaxonomies = createAsyncThunk(
  "account/updateFieldTaxonomies",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(
      accountApi.endpoints.updateFieldTaxonomies.initiate(data)
    );
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const updateDeliveryData = createAsyncThunk(
  "account/updateDeliveryData",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(
      accountApi.endpoints.updateDeliveryData.initiate(data)
    );
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const updateInvoiceData = createAsyncThunk(
  "account/updateInvoiceData",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(
      accountApi.endpoints.updateInvoiceData.initiate(data)
    );
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const updatePersonalData = createAsyncThunk(
  "account/updatePersonalData",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(
      accountApi.endpoints.updatePersonalData.initiate(data)
    );
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const confirmOptin = createAsyncThunk(
  "account/confirmOptin",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(accountApi.endpoints.confirmOptin.initiate(data));
    if (res.isError) {
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const validateCustomer = createAsyncThunk(
  "account/validateCustomer",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    if (expiredTokenDetected()) {
      await thunkApi.dispatch(getToken());
    }
    const res = await thunkApi.dispatch(accountApi.endpoints.validateCustomer.initiate(data));
    if (res.isError) {
      if (res.error.status === 404) {
        return res.error.status;
      }
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const createSubuserPublic = createAsyncThunk(
  "account/createSubuserPublic",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    const res = await thunkApi.dispatch(accountApi.endpoints.createSubuserPublic.initiate(data));
    if (res.isError) {
      if (res.error.status === 404) {
        return res.error.status;
      }
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const createSubuser = createAsyncThunk(
  "account/createSubuser",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    const res = await thunkApi.dispatch(accountApi.endpoints.createSubuser.initiate(data));
    if (res.isError) {
      if (res.error.status === 404) {
        return res.error.status;
      }
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);

export const checkEmail = createAsyncThunk(
  "account/email/check",
  async (data, thunkApi) => {
    thunkApi.dispatch(startFetching());
    const res = await thunkApi.dispatch(
      accountApi.endpoints.emailCheck.initiate(data)
    );
    if (res.isError) {
      if (res.error.status === 404 || res.error.status === 403) {
        return res.error.status;
      }
      return thunkApi.rejectWithValue(res.error);
    }
    thunkApi.dispatch(dataFetched(res.data));
    return res.data;
  }
);
