import { createSlice } from "@reduxjs/toolkit";
import {
  getAddress,
  getPersonalData,
  getPreselectedSearchSettings,
} from "./userThunk";

const sliceName = "userSlice";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  error: {},
  newData: null,
  resendUsernameStatus: null,
  taxonomies: [],
  occupationSpheres: [],
  personalData: [],
  invoiceAddress: [],
  deliveryAddress: [],
  isLoading: false,
};

export const userSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearState: () => initialState,
    startFetching: (state) => {
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
    },
    dataFetched: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    errorOccurred: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = true;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAddress.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAddress.fulfilled, (state, action) => {
      if (action.payload?.type === "invoice") {
        state.invoiceAddress = action.payload.fields;
      } else if (action.payload?.type === "delivery") {
        state.deliveryAddress = action.payload.fields;
      }
      state.isLoading = false;
    });
    builder.addCase(getPersonalData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getPersonalData.fulfilled, (state, action) => {
      state.personalData = action.payload.fields;
      state.isLoading = false;
    });
    builder.addCase(getPreselectedSearchSettings.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getPreselectedSearchSettings.fulfilled, (state, action) => {
      state.taxonomies = action?.payload?.results[0]?.taxonomy;
      state.occupationSpheres = action?.payload?.results[0]?.occupation_sphere;
      state.isLoading = false;
    });
  },
});

// Selector
export const userSelector = (state) => state.user;

const { actions, reducer } = userSlice;

// Actions
export const { clearState, startFetching, dataFetched, errorOccurred } =
  actions;

// Reducer
export default reducer;
