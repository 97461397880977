export const pageTitle = "Wolters Kluwer";
export const pageDescription =
  "Mit der Wolters Kluwer Benutzer-Selbstverwaltung aktualisieren Sie Ihre bei uns hinterlegten personenbezogenen Daten an einer zentralen Stelle, um so Ihren Verwaltungsaufwand zu reduzieren.";
export const featuredTopTitle = "Benutzer-Selbstverwaltung";
export const featuredTopDescription = pageDescription;
export const footerTagline = "When you have to be right";
export const serviceClient = "uss";
export const serviceEnvironment = process.env.REACT_APP_ENV;
export const serviceHost = window.location.origin;
export const ussAdmin = window.location.hostname.match(/localhost/) ? "http://localhost:4000" : window.location.origin + "/admin/benutzerverwaltung";

export const datadog = {
  applicationId: "4346c94f-d6f5-48a8-89d1-0ed42ceec6b0",
  clientToken: "pub2bf7965ab4d5e725e040d47e8b0e3eff",
  site: "datadoghq.eu",
  service: "csa-uss",
  env: process.env.REACT_APP_ENV,
  version: "1.0.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
};
export const SITE_KEY = "6Lcoa9wpAAAAAFlFd0zc8MoMSDZmbmonOzvOE8q_";
export const captcha = "https://www.google.com/recaptcha/api.js";

export const clientConfig = {
  "DE.WKOShop": {
    "btpLabel": "m Shop",
    "prd": {
      start: "https://shop.wolterskluwer-online.de/",
    },
    "stg": {
      start: "https://shop.wolterskluwer-online.de/",
    },
    "dev": {
      start: "https://shop.wolterskluwer-online.de/",
    },
  },
};
