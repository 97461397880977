import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ButtonField, Notification, Iconsvg } from "@wk/components-react16";
import "./DE.WkoShop.scss";
import { captcha, SITE_KEY } from "constants/config";
import {
  basicEmailOptions,
  basicPasswordOptions,
} from "constants/basicFormOptions";
import { authApi } from "services/auth/authApi";
import { checkEmail, createWkoAccount } from "services/account/accountThunk";
import loadGoogleCaptcha from "helpers/googleRecaptchaLoader";
import { getRegistrationFields } from "services/csa-api/csaThunk";
import { authSelector } from "services/auth/authSlice";
import { csaSelector } from "services/csa-api/csaSlice";
import { MultipageFields } from "./MultipageFields";
import parse from "html-react-parser";
import StepThrough from "../ui/stepThrough";
import Error403 from "../../.error/Error403";

const RegistrationWkoShop = ({ formHelper, formLegend, formRef }) => {
  const dispatch = useDispatch();
  const formManager = useForm({
    defaultValues: {
      salutation: 0,
      academicTitle: 5,
      firstname: "",
      lastname: "",
      company: "",
      phone_prefix: "",
      phone: "",
      eMail: "",
      password: "",
      passwordRepeat: "",
      street: "",
      streetNumber: "",
      zip: "",
      city: "",
      country_code: "D",
      deliveryAddress: false,
      deliverySalutation: 0,
      deliveryFirstname: "",
      deliveryLastname: "",
      deliveryCompany: "",
      deliveryStreet: "",
      deliveryStreetNumber: "",
      deliveryZip: "",
      deliveryCity: "",
      deliveryCountry: "D",
      agb: false,
      advertisement: false,
    },
    mode: "onTouched",
  });

  const {
    getValues,
    handleSubmit,
    clearErrors,
    setError,
    watch,
    trigger,
    formState: { errors },
  } = formManager;
  const { isAuthenticated } = useSelector(authSelector);
  const { registrationFields } = useSelector(csaSelector);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorNotification, setErrorNotification] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [activeRef, setActiveRef] = useState(null);
  const h2Ref = useRef(null);
  const h3Ref = useRef(null);
  const h3CheckboxRef = useRef(null);
  const showAdditionalFields = watch("deliveryAddress");

  useEffect(() => {
    dispatch(getRegistrationFields("DE.WKOShop"));
    // load the script by passing the URL
    loadGoogleCaptcha.loadCaptchaBadge(
      "recaptcha-key",
      `${captcha}?render=${SITE_KEY}&badge=bottomleft`,
      function () {}
    );
    loadGoogleCaptcha.toggleCaptchaBadge(true);
    return () => loadGoogleCaptcha.toggleCaptchaBadge(false);
  }, []);

  useEffect(() => {
    if (activeStep === 0 && activeRef === h2Ref) {
      h2Ref.current.scrollIntoView();
    } else if (activeStep === 1 && activeRef === h3Ref) {
      h3Ref.current.scrollIntoView();
    } else if (activeStep === 1 && activeRef === h3CheckboxRef) {
      h3CheckboxRef.current.scrollIntoView();
    }
  }, [activeStep]);

  const doLogin = async () => {
    const { data, isSuccess } = await dispatch(
      authApi.endpoints.getLoginFwdBtpLink.initiate({}, { forceRefetch: true })
    );
    if (isSuccess) {
      window.location.href = data.url;
    }
  };

  const onSubmit = () => {
    // get the token from google captcha by passing SITE_KEY and action
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "submit" })
        .then((token) => {
          submitData(token);
        });
    });
  };

  const submitData = async (token) => {
    const {
      salutation,
      academicTitle,
      firstname,
      lastname,
      company,
      phone_prefix,
      phone,
      eMail,
      password,
      agb,
      advertisement,
      street,
      streetNumber,
      zip,
      city,
      country_code,
      deliverySalutation,
      deliveryFirstname,
      deliveryLastname,
      deliveryCompany,
      deliveryStreet,
      deliveryStreetNumber,
      deliveryZip,
      deliveryCity,
      deliveryCountry,
      deliveryAddress,
    } = getValues();

    const userCreationData = {
      request_token: token,
      firstname,
      lastname,
      password,
      email: eMail,
      salutation_id: Number(salutation),
      title_id: Number(academicTitle),
      portal_id: "WKDSHOP",
      phone,
      phone_prefix,
      consent: {
        tos: agb,
        //marketing: advertisement,
      },
      invoice_address: {
        salutation_id: Number(salutation),
        title_id: Number(academicTitle),
        firstname,
        lastname,
        company,
        street: `${street} ${streetNumber}`,
        zip,
        city,
        country_code,
      },
      delivery_address: {},
    };

    if (deliveryAddress) {
      userCreationData.delivery_address = {
        salutation_id: Number(deliverySalutation),
        title_id: 5,
        firstname: deliveryFirstname,
        lastname: deliveryLastname,
        company: deliveryCompany,
        street: `${deliveryStreet} ${deliveryStreetNumber}`,
        zip: deliveryZip,
        city: deliveryCity,
        country_code: deliveryCountry,
      };
    }

    try {
      setSuccessMessage("Ihre Daten werden verarbeitet");
      setIsLoading(true);
      clearErrors();

      const result = await dispatch(createWkoAccount(userCreationData));
      const { status, data } = result.payload;

      if (status === 400) {
        handleResponseErrors(data.error);
        setErrorNotification(true);
        setIsLoading(false);
      } else if ([500, 401, 403].includes(status)) {
        setErrorNotification(true);
        setServerErrorMessage(
          "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später nochmal."
        );
        setIsLoading(false);
      } else {
        setSuccessMessage("Sie werden in Kürze zur Anmeldung weitergeleitet");
        setTimeout(() => {
          doLogin();
        }, [3000]);
      }
    } catch (error) {
      setServerErrorMessage(
        "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
      );
      setIsLoading(false);
    }
  };

  const handleFieldError = (field) => {
    const { property, description, constraints } = field;
    const options = {
      eMail: basicEmailOptions,
      password: basicPasswordOptions,
    }[property];

    if (!options) return;

    for (const constraint in constraints) {
      if (constraints[constraint]) {
        const option = options[constraint] || options;
        const message = option.message || option;
        setError(property, { message });
        break;
      }
    }
  };

  const handleResponseErrors = (errors) => {
    errors.forEach(handleFieldError);
  };

  const nextStep = async () => {
    const currentFields = registrationFields[activeStep].fields.map(
      (field) => field.name
    );

    if (activeStep === 0) {
      const isValidStep0 = await trigger(currentFields);
      const { eMail, password, passwordRepeat } = getValues();

      if (password !== passwordRepeat) {
        setError("password", {
          type: "manual",
          message: "",
        });
        setError("passwordRepeat", {
          type: "manual",
          message: "Die beiden Passwörter stimmen nicht überein.",
        });
      }
      try {
        await window.grecaptcha.ready(async () => {
          const token = await window.grecaptcha.execute(SITE_KEY, {
            action: "submit",
          });

          const result = await dispatch(
            checkEmail({ username: eMail, request_token: token })
          );

          if (result.payload && result.payload.success) {
            setError("eMail", {
              type: "manual",
              message: "Diese E-Mail-Adresse ist bereits registriert.",
            });
          } else if (isValidStep0 && Object.keys(errors).length === 0) {
            setActiveStep((prevStep) => prevStep + 1);
          }
        });
      } catch (error) {
        throw new error();
      }
    } else if (activeStep === 1) {
      const isValidStep1 = await trigger(currentFields);
      const { deliveryAddress } = getValues();
      const mandatoryFieldNames =
        registrationFields[1].fields[4].additionalFields[0].fields
          .filter((field) => field.mandatory)
          .map((field) => field.name);

      let additionalFieldsValid = true;

      if (deliveryAddress) {
        additionalFieldsValid = await trigger(mandatoryFieldNames);
      } else {
        clearErrors(mandatoryFieldNames);
      }

      if (additionalFieldsValid && isValidStep1) {
        setActiveStep((prevStep) => prevStep + 1);
      }
    }
  };

  const prevStep = () => setActiveStep((prev) => prev - 1);

  const renderSummaryBoxes = () => {
    const stepOneFields = registrationFields[0].fields;
    const stepTwoFields = registrationFields[1].fields.filter(
      (field) => field.name !== "deliveryAddress"
    );
    const deliveryAddressFields = registrationFields[1].fields.find(
      (field) => field.name === "deliveryAddress"
    )?.additionalFields[0].fields;

    const renderFieldValue = (field) => {
      const value = getValues(field.name);

      if (field.type === "select" || field.type === "radio") {
        const selectedOption = field.selection.find(
          (option) => option.value === value
        );
        return selectedOption ? selectedOption.label : "-";
      }

      if (field.type === "password") {
        return "•".repeat(value.length);
      }

      return value ? value : "-";
    };

    const renderSection = (fields, sectionTitle, stepIndex, ref) => (
      <div className="summary-box">
        <div className="summary-title">
          <h3>{sectionTitle}</h3>
          <ButtonField mode="text">
            <button
              type="button"
              className="wk-button-review-field"
              onClick={() => {
                setActiveRef(ref);
                setActiveStep(stepIndex);
              }}
              disabled={activeStep === stepIndex}
            >
              <Iconsvg name="pencil" />
              Bearbeiten
            </button>
          </ButtonField>
        </div>
        {fields.map(
          (field) =>
            field.name !== "passwordRepeat" && field.name !== "phone" && (
              <div key={field.name} className="summary-field">
                { field.name === "phone_prefix" && <>
                  <div className="summary-label">Telefon</div>
                  <div className="summary-value">
                    {renderFieldValue(field)}
                    {renderFieldValue(field) !== "-" && ` - ${getValues("phone")}`}
                  </div>
                </>}
                {(field.name !== "phone_prefix" && field.name !== "phone") && <>
                  <div className="summary-label">{field.label}</div>
                  <div className="summary-value">{renderFieldValue(field)}</div>
                </>}
              </div>
            )
        )}
      </div>
    );

    return (
      <div className="summary-container">
        {renderSection(
          stepOneFields,
          registrationFields[0].sectionTitle,
          0,
          h2Ref
        )}
        {renderSection(
          stepTwoFields,
          registrationFields[1].sectionTitle,
          1,
          h3Ref
        )}

        {getValues("deliveryAddress") &&
          renderSection(
            deliveryAddressFields,
            registrationFields[1].fields.find(
              (field) => field.name === "deliveryAddress"
            )?.additionalFields[0].sectionTitle,
            1,
            h3CheckboxRef
          )}
      </div>
    );
  };

  const renderFields = (fields) => {
    return fields?.map((field) => {
      if (field.type === "checkbox" && field.additionalFields) {
        return (
          <>
            <MultipageFields
              formManager={formManager}
              inputs={[field]}
              submitDisabled={submitDisabled}
              setSubmitDisabled={setSubmitDisabled}
              setErrorNotification={setErrorNotification}
            />
            <div id={field.name} key={field.name}>
              {showAdditionalFields &&
                <>
                  <h3 className="wk-form-sectionTitle" ref={h3CheckboxRef}>
                    {field.additionalFields[0]?.sectionTitle}
                  </h3>
                  {renderFields(field.additionalFields[0].fields)}
                </>
              }
            </div>
          </>
        );
      } else {
        return (
          <MultipageFields
            key={field.name}
            formManager={formManager}
            inputs={[field]}
            submitDisabled={submitDisabled}
            setSubmitDisabled={setSubmitDisabled}
            setErrorNotification={setErrorNotification}
          />
        );
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <div style={{textAlign: "center"}}>
          <Iconsvg name="spinner" isSpin size={32} />
          { successMessage !== null && <>
            <h1 style={{paddingTop: "0.5rem"}}>
              Bitte warten
            </h1>
            <span>{successMessage}</span>
          </>
          }
        </div>
      ) : (
        <>
          { isAuthenticated ?
            <Error403/> : <form
            className="wk-form wk-client-wkoshop"
            id="wk-form-registration"
            ref={formRef}
            onSubmit={handleSubmit(onSubmit)}
          >
            <legend className="wk-form-legend">{formLegend}</legend>
            <div className="wk-field-helper">{formHelper}</div>
            {registrationFields && (
              <StepThrough activeIndex={activeStep} steps={registrationFields}>
                {(registrationFields[0]?.sectionTitle ||
                  registrationFields[0]?.sectionDescription) && (
                  <>
                    <h2 className="wk-form-stepTitle" ref={h2Ref}>
                      {registrationFields[activeStep]?.stepTitle}
                    </h2>
                    { registrationFields[activeStep]?.stepTitle !== registrationFields[activeStep]?.sectionTitle &&
                      <h3 className="wk-form-sectionTitle" ref={h3Ref}>
                        {registrationFields[activeStep]?.sectionTitle}
                      </h3>
                    }
                    <div className="wk-form-sectionDescription">
                      {registrationFields[activeStep]?.sectionDescription}
                    </div>
                  </>
                )}
                {errorNotification && (
                  <Notification
                    type="error"
                    heading="Registrierung nicht erfolgreich"
                    hasCloseButton={false}
                  >
                    <span slot="notificationContent">
                      {serverErrorMessage}
                      {!serverErrorMessage &&
                        "Bitte überprüfen Sie Ihre Eingaben und füllen Sie alle Pflichtfelder aus."}
                    </span>
                  </Notification>
                )}
                {activeStep === registrationFields.length - 1 ? (
                  <>
                    {renderSummaryBoxes()}
                    <div className="wk-form-multipage-last">
                      <h3 className="wk-form-sectionTitle">AGB und Datenschutz</h3>
                      <fieldset className="wk-technical-fieldset">
                        {renderFields(registrationFields[activeStep]?.fields)}
                      </fieldset>
                    </div>
                  </>
                ) : (
                  <fieldset className="wk-technical-fieldset">
                    {renderFields(registrationFields[activeStep]?.fields)}
                  </fieldset>
                )}
                {registrationFields[activeStep]?.sectionFooter && (
                  <div className="fieldset-addition-div">
                    {parse(registrationFields[activeStep]?.sectionFooter)}
                  </div>
                )}
                {activeStep < registrationFields.length - 1 && (
                  <div className="wk-form-action-button-group">
                    <ButtonField mode="ghost">
                      <button
                        type="button"
                        className="wk-button-full-width"
                        onClick={prevStep}
                        disabled={activeStep === 0}
                      >
                        <Iconsvg name="arrow-left" /> Zurück
                      </button>
                    </ButtonField>
                    <ButtonField>
                      <button
                        className="wk-button-full-width"
                        onClick={nextStep}
                        mode={"text"}
                        type="button"
                      >
                        Weiter <Iconsvg name="arrow-right" />
                      </button>
                    </ButtonField>
                  </div>
                )}

                {activeStep === registrationFields.length - 1 && (
                  <div className="wk-form-action-button-group">
                    <ButtonField mode="ghost">
                      <button
                        type="button"
                        className="wk-button-full-width"
                        onClick={prevStep}
                        disabled={activeStep === 0}
                      >
                        <Iconsvg name="arrow-left" /> Zurück
                      </button>
                    </ButtonField>
                    <ButtonField>
                      <button
                        type="submit"
                        className="wk-button-full-width"
                        disabled={submitDisabled}
                      >
                        Kostenlos registrieren
                      </button>
                    </ButtonField>
                  </div>
                )}
              </StepThrough>
            )}
            <div className="wk-form-footer">
              Sie haben bereits ein Konto?{" "}
              <a className="wk-show-pointer" onClick={doLogin}>
                Jetzt anmelden
              </a>
            </div>
          </form>
          }
        </>
      )}
    </>
  );
};

export default RegistrationWkoShop;
