import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { serviceEnvironment } from "constants/config";

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/account",
    credentials: "include",
    prepareHeaders: (headers) => {
      // OneID token.
      const token = localStorage.getItem(`${serviceEnvironment}_token`);
      if (token) {
        headers.set("x-token", token);
      }
      // CSRF token.
      const csrf = sessionStorage.getItem("csrf-token");
      if (csrf) {
        headers.set("x-csrf-token", csrf);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    resendUsername: builder.query({
      query: (data) => ({
        url: `/username/resend`,
        method: "POST",
        body: data,
      }),
    }),
    sendResetPasswordLink: builder.query({
      query: (data) => ({
        url: `/password/reset`,
        method: "POST",
        body: data,
      }),
    }),
    updatePublicPassword: builder.query({
      query: (data) => ({
        url: `/password/update`,
        method: "POST",
        body: data,
      }),
    }),
    updateFieldOccupationSpheres: builder.mutation({
      query: (data) => ({
        url: "/SetOccupationSpheres",
        method: "PATCH",
        body: data,
      }),
    }),
    updateFieldTaxonomies: builder.mutation({
      query: (data) => ({
        url: "/SetTaxonomies",
        method: "PATCH",
        body: data,
      }),
    }),
    updateFieldConsent: builder.mutation({
      query: (data) => ({
        url: "/updateConsent",
        method: "PATCH",
        body: data,
      }),
    }),
    updateFieldPassword: builder.mutation({
      query: (data) => ({
        url: "/updatePassword",
        method: "PATCH",
        body: data,
      }),
    }),
    updateDeliveryData: builder.mutation({
      query: (data) => ({
        url: "/updateDeliveryData",
        method: "PATCH",
        body: data,
      }),
    }),
    updateInvoiceData: builder.mutation({
      query: (data) => ({
        url: "/updateInvoiceData",
        method: "PATCH",
        body: data,
      }),
    }),
    updatePersonalData: builder.mutation({
      query: (data) => ({
        url: "/updatePersonalData",
        method: "PATCH",
        body: data,
      }),
    }),
    createUserAccount: builder.query({
      query: (data) => ({
        url: `/create`,
        method: "POST",
        body: data,
      }),
    }),
    createSubuserAccount: builder.query({
      query: (data) => ({
        url: `/create/subuser`,
        method: "POST",
        body: data,
      }),
    }),
    createWkoAccount: builder.query({
      query: (data) => ({
        url: `/create/wkoshop`,
        method: "POST",
        body: data,
      }),
    }),
    importUserAccount: builder.query({
      query: (data) => ({
        url: `/import`,
        method: "POST",
        body: data,
      }),
    }),
    importSubuserAccount: builder.query({
      query: (data) => ({
        url: `/import/subuser`,
        method: "POST",
        body: data,
      }),
    }),
    resendOptinEmail: builder.query({
      query: (data) => ({
        url: `/optin/resend`,
        method: "POST",
        body: data,
      }),
    }),
    confirmOptin: builder.query({
      query: (data) => ({
        url: `/optin/confirm`,
        method: "POST",
        body: data,
      }),
    }),
    validateCustomer: builder.query({
      query: (customer) => ({
        url: "/self/validate",
        method: "POST",
        body: {
          customer: customer,
        }
      }),
    }),
    createSubuserPublic: builder.query({
      query: (data) => ({
        url: "/self/create",
        method: "POST",
        body: data,
      }),
    }),
    createSubuser: builder.query({
      query: (data) => ({
        url: "/self/create/subuser",
        method: "POST",
        body: data,
      }),
    }),
    emailCheck: builder.query({
      query: (data) => ({
        url: "/email/check",
        method: "POST",
        body: data,
      }),
    }),
  }),
});
export const { useGetAccountIdByUsernameQuery, useGetAccountDataByIdQuery } =
  accountApi;
