import Error403 from "../../pages/.error/Error403";

export const ProtectedRoute = ({ children, hide, show }) => {
  const clientId = sessionStorage.getItem("clientId");

  if (
    (show && show.includes(clientId)) ||
    (hide && !hide.includes(clientId))
  ) {
    return children;
  }

  return <Error403/>;
};
