import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonField, Iconsvg } from "@wk/components-react16";
import { authApi } from "services/auth/authApi";
import { authSelector } from "services/auth/authSlice";

export const LogoutButton = (props) => {
  const { isAuthenticated } = useSelector(authSelector);
  const dispatch = useDispatch();

  const doLogout = async () => {
    if (!isAuthenticated) {
      return;
    }
    const { data, isSuccess } = await dispatch(
      authApi.endpoints.getLogoutUrl.initiate({}, { forceRefetch: true })
    );
    if (isSuccess) {
      window.location.href = data.url;
      localStorage.clear();
    }
  };

  return (
    <ButtonField mode="text" iconPosition="left">
      <button id="wk-csa-logout" style={props.style} onClick={doLogout}>
        <Iconsvg name="logout" />
        Abmelden
      </button>
    </ButtonField>
  );
}

export default LogoutButton;
