import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Iconsvg } from "@wk/components-react16";
import { routeRegistration } from "constants/path.routing";
import { authSelector } from "services/auth/authSlice";
import { validateCustomer } from "services/account/accountThunk";
import BasicRegistration from "./ui/BasicRegistration";
import IpRegistration from "./ui/IpRegistration";
import Error403 from "../.error/Error403";
import RegistrationWkoShop from "./custom/DE.WkoShop";

export const Registration = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, isIpUser, isAdmin, noRegistrationPersonally } =
    useSelector(authSelector);
  const formRef = useRef(null);
  const clientId = sessionStorage.getItem("clientId");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (isAuthenticated && isIpUser) {
      dispatch(validateCustomer("")).then((res) => {
        if (res.payload?.customer) {
          window.location.pathname = `${routeRegistration}/${res.payload.customer}`;
        } else if (noRegistrationPersonally) {
          window.location.href = process.env.REACT_APP_LIBRARY;
        } else {
          setIsLoading(false);
        }
      });
    }
  }, [isIpUser]);

  const getRegistrationForm = () => {
    if (clientId === "DE.WKOShop") {
      return (
        <RegistrationWkoShop
          formLegend="Registrierung"
          formHelper=""
          formRef={formRef}
        />
      );
    } else {
      if (!isAuthenticated) {
        return (
          <BasicRegistration
            formLegend="Registrierung"
            formHelper=""
            formRef={formRef}
          />
        );
      } else if (isIpUser && !noRegistrationPersonally && !isAdmin) {
        return (
          <IpRegistration
            formLegend="Registrierung"
            formHelper=""
            formRef={formRef}
          />
        );
      }
      return <Error403 />;
    }
  };

  return (
    <>
      {isLoading ? (
        <div style={{ textAlign: "center" }}>
          <Iconsvg name="spinner" isSpin size={32} />
        </div>
      ) : (
        <>{getRegistrationForm()}</>
      )}
    </>
  );
};

export default Registration;
