import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import {
  CheckboxField,
  CheckboxGroupField,
  InputField,
  Multiselect,
  PasswordField,
  RadioField,
  RadioGroupField,
  SelectField,
} from "@wk/components-react16";
import PasswordStrengthBar from "components/form/PasswordStrengthBar";

export const MultipageFields = ({
  formManager,
  inputs,
  setSubmitDisabled,
  submitDisabled,
  setErrorNotification,
}) => {
  const {
    register,
    setValue,
    trigger,
    watch,
    getValues,
    formState: { isSubmitted, errors, touchedFields },
  } = formManager;
  const [choice, setChoice] = useState({});
  const [multiselection, setMultiselection] = useState({});
  const [passwordType, setPasswordType] = useState({});
  const [passwordValue, setPasswordValue] = useState({});

  useEffect(() => {
    Object.keys(multiselection).forEach((fieldName) => {
      setValue(fieldName, multiselection[fieldName]);
      if (isSubmitted) {
        trigger(fieldName);
      }
    });
  }, [multiselection]);

  useEffect(() => {
    Object.keys(choice).forEach((fieldName) => {
      setValue(fieldName, choice[fieldName]);
      if (isSubmitted) {
        trigger(fieldName);
      }
    });
  }, [choice]);

  useEffect(() => {
    Object.keys(passwordValue).forEach((fieldName) => {
      setValue(fieldName, passwordValue[fieldName]);
      if (isSubmitted) {
        trigger(fieldName);
      }
    });
  }, [passwordValue]);

  useEffect(() => {
    if (!Object.keys(errors).length) {
      setErrorNotification(false);
    }
  }, [Object.keys(errors).length]);

  const validationError = (fieldName) => errors[fieldName];

  const getIndicator = (fieldMandatory) => {
    if (fieldMandatory === true) {
      return "required";
    } else if (fieldMandatory === false) {
      return "optional";
    }
    return "none";
  };

  const getStatus = (fieldName) => {
    const value = getValues(fieldName);
    if (value && !validationError(fieldName)) {
      if (!touchedFields[fieldName]) {
        return "none";
      }
      return "success";
    } else if (validationError(fieldName)) {
      return "error";
    } else {
      return "none";
    }
  };

  const getError = (fieldName) => {
    return [
      {
        label: errors[fieldName].message,
        labelId: `${fieldName}-error-${errors[fieldName].type}`,
      },
    ];
  };

  const fieldChoiceErrorClass = (field) => {
    if (isSubmitted && field.mandatory === true) {
      const allChoiceElements = document.querySelectorAll(
        `.wk-field-choice-${field.name}`
      );
      allChoiceElements.forEach((element) => {
        if (
          element.nextElementSibling.classList.contains("cg-field-choice-box")
        ) {
          if (errors[field.name] && !choice[field.name]) {
            element.nextElementSibling.classList.add("wk-field-choice-error");
          } else {
            element.nextElementSibling.classList.remove(
              "wk-field-choice-error"
            );
          }
        } else if (field.name === "agb") {
          if (
            (errors[field.name] && !choice[field.name]) ||
            choice[field.name] === false
          ) {
            element.nextElementSibling.nextElementSibling.classList.add(
              "wk-field-choice-error"
            );
          } else {
            element.nextElementSibling.nextElementSibling.classList.remove(
              "wk-field-choice-error"
            );
          }
        }
      });
    }
  };

  const preparePasswordOptions = (options) => {
    if (options?.validate) {
      return {
        ...options,
        validate: options.validate(watch),
      };
    }
    return options;
  };

  const createChoiceKey = (fieldName) => {
    if (!choice.hasOwnProperty(fieldName)) {
      setChoice({
        ...choice,
        [fieldName]: formManager.control._formValues[fieldName],
      });
    }
  };

  const createMultiselectKey = (fieldName) => {
    if (!multiselection.hasOwnProperty(fieldName)) {
      setMultiselection({
        ...multiselection,
        [fieldName]: formManager.control._formValues[fieldName],
      });
    }
  };

  const createPasswordKey = (fieldName) => {
    if (!passwordType.hasOwnProperty(fieldName)) {
      setPasswordType({ ...passwordType, [fieldName]: "password" });
    }
    if (!passwordValue.hasOwnProperty(fieldName)) {
      setPasswordValue({
        ...passwordValue,
        [fieldName]: formManager.control._formValues[fieldName],
      });
    }
  };

  const changeMultiselectValues = (fieldName, item) => {
    if (item.isSelected) {
      setMultiselection({
        ...multiselection,
        [fieldName]: [...multiselection[fieldName], item],
      });
    } else {
      setMultiselection({
        ...multiselection,
        [fieldName]: [
          ...multiselection[fieldName].filter((e) => e.id !== item.id),
        ],
      });
    }
  };

  const resetMultiselectValues = (fieldName) => {
    setMultiselection({ ...multiselection, [fieldName]: [] });
  };

  const changePasswordMode = (fieldName) => {
    if (passwordType[fieldName] === "password") {
      setPasswordType({ ...passwordType, [fieldName]: "text" });
    } else {
      setPasswordType({ ...passwordType, [fieldName]: "password" });
    }
  };

  const fields = inputs.map((field) => {
    if (field.type === "radio") {
      createChoiceKey(field.name);
      fieldChoiceErrorClass(field);
      return (
        <RadioGroupField
          key={field.name}
          alignment={field.alignment}
          label={field.label}
          indicator={getIndicator(field.mandatory)}
        >
          {field.selection.map((element) => {
            return (
              <RadioField
                key={element.name}
                label={element.label}
                description={field.description}
              >
                <input
                  {...register(field.name, field.options)}
                  type={field.type}
                  className={`wk-field-choice-${field.name}`}
                  name={element.name}
                  value={element.value}
                  checked={choice[field.name] === element.value}
                  onChange={(event) =>
                    setChoice({
                      ...choice,
                      [field.name]: event.target.value,
                    })
                  }
                />
              </RadioField>
            );
          })}
          {field.options &&
            validationError(field.name) &&
            !choice[field.name] && (
              <div
                data-e2e="cg-field-error"
                aria-live="polite"
                role="alert"
                id={`${field.name}-error-${errors[field.name].type}`}
                className="wk-field-error"
              >
                {errors[field.name]?.message}
              </div>
            )}
        </RadioGroupField>
      );
    }

    if (field.type === "select") {
      return (
        <SelectField
          key={field.name}
          label={field.label}
          labelFor={field.name}
          description={field.description}
          indicator={getIndicator(field.mandatory)}
          status={field.options && getStatus(field.name)}
          errors={
            isSubmitted && validationError(field.name)
              ? getError(field.name)
              : []
          }
        >
          <select
            id={field.name}
            name={field.name}
            {...register(field.name, field.options)}
          >
            {field.selection.map((element) => {
              return (
                <option key={element.label} value={element.value}>
                  {element.label}
                </option>
              );
            })}
          </select>
          {field.options &&
            validationError(field.name) &&
            !choice[field.name] && (
              <div
                data-e2e="cg-field-error"
                aria-live="polite"
                role="alert"
                id={`${field.name}-error-${errors[field.name].type}`}
                className="wk-field-error"
              >
                {errors[field.name]?.message}
              </div>
            )}
        </SelectField>
      );
    }

    if (field.type === "multiselect") {
      createMultiselectKey(field.name);
      return (
        <Multiselect
          {...register(field.name, field.options)}
          key={field.name}
          items={field.selection}
          pillLabel={field.label}
          placeholder={field.placeholder}
          label={field.label}
          description={field.description}
          indicator={getIndicator(field.mandatory)}
          onPillUserRequest={() => resetMultiselectValues(field.name)}
          onItemUserRequest={(event) =>
            changeMultiselectValues(field.name, event)
          }
          status={field.options && getStatus(field.name)}
          errors={
            isSubmitted && validationError(field.name)
              ? getError(field.name)
              : []
          }
        />
      );
    }

    if (field.type === "checkbox") {
      createChoiceKey(field.name);
      fieldChoiceErrorClass(field);
      if (
        field.name === "agb" ||
        field.name === "deliveryAddress" ||
        field.name === "advertisement"
      ) {
        return (
          <div key={field.name} className="wk-form-checkbox-absolute">
            <CheckboxGroupField
              {...register(field.name, field.options)}
              alignment={field.alignment}
              label={field.label}
              isLabelHidden={true}
              indicator={getIndicator(field.mandatory)}
            >
              <CheckboxField key={field.name}>
                <input
                  name={field.name}
                  type="checkbox"
                  className={`wk-field-choice-${field.name}`}
                  checked={choice[field.name]}
                  onChange={(event) => {
                    setChoice({
                      ...choice,
                      [field.name]: event.target.checked,
                    });
                    if (field.name === "agb") {
                      setSubmitDisabled(!submitDisabled);
                    }
                  }}
                />
                <span className="input-field-label-absolute">
                  {/*{!field.mandatory &&
                    <span aria-hidden="true">
                      {"Optional: "}
                    </span>
                  }*/}
                  {parse(field.text)}
                  {/*{field.mandatory &&
                    <div
                      data-e2e="cg-field-required-indicator"
                      className="cg-field-required-indicator-2-12-0">
                      <span aria-hidden="true">
                        *
                      </span>
                      <div data-e2e="cg-sr-only" className="cg-sr-only-2-12-0">
                        Required field
                      </div>
                    </div>
                  }*/}
                </span>
              </CheckboxField>
              {field.options &&
                validationError(field.name) &&
                !choice[field.name] && (
                  <div
                    data-e2e="cg-field-error"
                    aria-live="polite"
                    role="alert"
                    id={`${field.name}-error-${errors[field.name].type}`}
                    className="wk-field-error"
                  >
                    {errors[field.name]?.message}
                  </div>
                )}
            </CheckboxGroupField>
          </div>
        );
      }
      return (
        <CheckboxGroupField
          key={field.name}
          alignment={field.alignment}
          label={field.label}
          indicator={getIndicator(field.mandatory)}
        >
          {field.selection?.map((element) => {
            return (
              <CheckboxField key={element.name} description={field.description}>
                <input
                  name={element.name}
                  type="checkbox"
                  className={`wk-field-choice-${field.name}`}
                  checked={choice[field.name] === element.value}
                  onChange={(event) =>
                    setChoice({
                      ...choice,
                      [field.name]: event.target.checked,
                    })
                  }
                />
              </CheckboxField>
            );
          })}
          {field.options &&
            validationError(field.name) &&
            !choice[field.name] && (
              <div
                data-e2e="cg-field-error"
                aria-live="polite"
                role="alert"
                id={`${field.name}-error-${errors[field.name].type}`}
                className="wk-field-error"
              >
                {errors[field.name]?.message}
              </div>
            )}
        </CheckboxGroupField>
      );
    }

    if (field.type === "password") {
      createPasswordKey(field.name);
      return (
        <PasswordField
          key={field.name}
          label={field.label}
          labelFor={field.name}
          type={passwordType[field.name]}
          indicator={getIndicator(field.mandatory)}
          status={field.options && getStatus(field.name)}
          errors={validationError(field.name) ? getError(field.name) : []}
          onModeChange={(event) => changePasswordMode(field.name, event)}
          i18n={{
            revealPasswordText: "Passwort anzeigen",
            hidePasswordText: "Passwort verbergen",
          }}
        >
          <input
            {...register(field.name, preparePasswordOptions(field.options))}
            type={passwordType[field.name]}
            id={field.name}
            name={field.name}
            placeholder={field.placeholder}
            value={passwordValue[field.name]}
            autoComplete={field.autocomplete}
            onChange={(event) => {
              setPasswordValue({
                ...passwordValue,
                [field.name]: event.target.value,
              });
            }}
          />
          {field.strengthBar === true && (
            <PasswordStrengthBar checkValue={passwordValue[field.name]} />
          )}
        </PasswordField>
      );
    }

    if (field.name === "phone_prefix") {
      return <>
        <div style={{display: "inline-block", maxWidth: "30%"}}>
          <InputField
            key={field.name}
            label="Telefon"
            labelFor={field.name}
            description={field.description}
            indicator={getIndicator(field.mandatory)}
            status={field.options && getStatus(field.name)}
            //errors={validationError(field.name) ? getError(field.name) : []}
          >
            <input
              {...register(field.name, field.options)}
              type="text"
              id={field.name}
              name={field.name}
              placeholder="Vorwahl"
              autoComplete={field.autocomplete}
            />
          </InputField>
        </div>
        <div style={{
          position: "relative",
          top: "-1.9rem",
          display: "inline-block",
          marginLeft: "1%",
          marginRight: "1%",
          maxWidth: "3%",
        }}>
          -
        </div>
      </>
    }

    if (field.name === "phone") {
      return <>
          <div className="wk-field-input-phone-width" style={{display: "inline-block"}}>
          <InputField
            key={field.name}
            labelFor={field.name}
            description={field.description}
            indicator={getIndicator(field.mandatory)}
            status={field.options && getStatus(field.name)}
            //errors={validationError(field.name) ? getError(field.name) : []}
          >
            <input
              {...register(field.name, field.options)}
              type="text"
              id={field.name}
              name={field.name}
              placeholder="Telefonnummer eingeben"
              autoComplete={field.autocomplete}
            />
          </InputField>
        </div>
        {field.options &&
          (validationError("phone_prefix") || validationError("phone")) &&
          <div
            data-e2e="cg-field-error"
            aria-live="polite"
            role="alert"
            id={`${field.name}-error-input`}
            className="wk-field-error"
            style={{
              marginTop: "-0.9rem",
              marginBottom: "0.5rem",
              position: "relative",
              fontSize: "0.875rem"
          }}
          >
            {errors["phone_prefix"]?.message || errors["phone"]?.message}
          </div>
        }
      </>
    }

    return (
      <InputField
        key={field.name}
        label={field.label}
        labelFor={field.name}
        description={field.description}
        indicator={getIndicator(field.mandatory)}
        status={field.options && getStatus(field.name)}
        errors={validationError(field.name) ? getError(field.name) : []}
      >
        <input
          {...register(field.name, field.options)}
          type="text"
          id={field.name}
          name={field.name}
          placeholder={field.placeholder}
          autoComplete={field.autocomplete}
        />
      </InputField>
    );
  });

  return <>{fields}</>;
};

export default MultipageFields;
