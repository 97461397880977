import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { serviceClient, serviceEnvironment } from "constants/config";

export const csaApi = createApi({
  reducerPath: "csaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/csa",
    credentials: "include",
    prepareHeaders: (headers) => {
      // OneID token.
      const token = localStorage.getItem(`${serviceEnvironment}_token`);
      if (token) {
        headers.set("x-token", token);
      }
      // CSRF token.
      const csrf = sessionStorage.getItem("csrf-token");
      if (csrf) {
        headers.set("x-csrf-token", csrf);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getEnvConfig: builder.query({
      query: (data) => ({
        url: "/config",
        method: "POST",
        body: {
          client: serviceClient,
          clientId: data,
        },
      }),
    }),
    getCustomerData: builder.query({
      query: (customer) => ({
        url: "/self",
        method: "POST",
        body: {
          customer: customer,
        },
      }),
    }),
    getSession: builder.query({
      query: () => ({
        url: "/session",
        method: "POST",
        body: {
          redirectUrl: encodeURI(window.location.href),
        },
      }),
    }),
    getRegistrationFields: builder.query({
      query: (clientId) => ({
        url: `/registration`,
        method: "POST",
        body: {
          clientId: clientId,
          activeSession: JSON.parse(sessionStorage.getItem("activeSession")),
        }
      }),
    }),
  }),
});
