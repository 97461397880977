import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  ButtonField,
  Iconsvg,
} from "@wk/components-react16";
import {
  AvatarImage,
  ButtonField as ButtonFieldv3,
  FishbowlAvatars,
  FishbowlContainer,
  FishbowlLabels,
  FishbowlOrganizationLabel,
  FishbowlUserAvatar,
  FishbowlUserLabel,
  Icon,
  SideModal,
  SrOnly,
  VerticalLayout,
  VerticalLayoutContent,
} from "@wk/components-v3-react16";
import styles from "./UserMenu.module.scss";
import { ipUserNavigation, userNavigation } from "constants/navigation.constants";
import { authSelector } from "services/auth/authSlice";
import LogoutButton from "./LogoutButton";
import NamedLoginButton from "./NamedLoginButton";

export const UserMenu = () => {
  const { firstname, lastname, username, isIpUser } = useSelector(authSelector);
  const clientId = sessionStorage.getItem("clientId");
  const [isOpen, setIsOpen] = useState(false);

  const userMenu = userNavigation
    .map(({ path, label, icon, showForClientId, hideForClientId }) => {
      if (
        (!showForClientId && !hideForClientId) ||
        (showForClientId && showForClientId.includes(clientId)) ||
        (hideForClientId && !hideForClientId.includes(clientId))
      ) {
        return <li>
          <ButtonField
            mode="text"
            iconPosition="left"
            onItemClick={() => setIsOpen(false)}
          >
            <a key={path} href={path}>
              {icon && <Iconsvg name={icon} />}
              {label}
            </a>
          </ButtonField>
        </li>;
      } else {
        return ;
      }
    });

  const ipUserMenu = ipUserNavigation
    .map(({ path, label, icon, showForClientId, hideForClientId }) => {
      if (
        (!showForClientId && !hideForClientId) ||
        (showForClientId && showForClientId.includes(clientId)) ||
        (hideForClientId && !hideForClientId.includes(clientId))
      ) {
        return <li>
          <ButtonField
            mode="text"
            iconPosition="left"
            onItemClick={() => setIsOpen(false)}
          >
            <a key={path} href={path}>
              {icon && <Iconsvg name={icon} />}
              {label}
            </a>
          </ButtonField>
        </li>
      } else {
        return ;
      }
    });

  const toggleUserMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleUserRequest = (origin) => {
    if (origin === "overlay" || origin === "esc" || origin === "close") {
      setIsOpen(false);
    }
  };

  return (
    <>
      <div className={`wk-banner-action-bar ${styles.userMenu}`}>
        <ButtonField mode="text">
          <button
            type="button"
            id="usermenu"
            data-dd-privacy="mask"
            onClick={() => toggleUserMenu()}
            className={`${styles.headerButton}`}
          >
            <span className="wk-sr-only">Benutzermenü öffnen</span>
            <FishbowlContainer>
              <FishbowlLabels flowClass={`${styles.headerUserDetails}`}>
                <FishbowlUserLabel>
                  {`${firstname} ${lastname}`}
                </FishbowlUserLabel>
              </FishbowlLabels>
              <FishbowlAvatars>
                <FishbowlUserAvatar>
                  <AvatarImage flowClass={`${styles.headerAvatarDefault}`} size="medium">
                    <Icon
                      name={`${isIpUser ? "wk-icon-filled-office" : "wk-icon-filled-user"}`}
                      srTitle="placeholder image"
                      flowClass={`${isIpUser ? styles.userOrganisation : styles.userPersonal}`}
                    />
                  </AvatarImage>
                </FishbowlUserAvatar>
              </FishbowlAvatars>
            </FishbowlContainer>
          </button>
        </ButtonField>
        <SideModal
          isOpen={isOpen}
          srLabelledBy="user-menu"
          onUserRequest={(event) => handleUserRequest(event)}
        >
          <VerticalLayout>
            <VerticalLayoutContent>
              <div className={`${styles.modalHeaderButtons}`}>
                <ButtonFieldv3 type="icon" size="large">
                  <button
                    type="button"
                    flow-id="close-button"
                    onClick={() => handleUserRequest("close")}>
                    <Icon name="wk-icon-close"></Icon>
                    <SrOnly>Close side-modal</SrOnly>
                  </button>
                </ButtonFieldv3>
              </div>
              <h1 id="user-menu" className="wk-sr-only">Benutzermenü</h1>
              <div className={`${styles.modalContent}`}>
                <h2 className={`${styles.modalContentTitle}`}>Mein Konto</h2>
                <div className={`${styles.modalContentUserInfo}`}>
                  <div className={`${styles.userAvatar}`}>
                    <FishbowlContainer>
                      <FishbowlAvatars>
                        <FishbowlUserAvatar>
                          <AvatarImage flowClass={`${styles.avatarDefault}`} size="extra-large">
                            <Icon
                              name={`${isIpUser ? "wk-icon-filled-office" : "wk-icon-filled-user"}`}
                              srTitle="placeholder image"
                              flowClass={`${isIpUser ? styles.userOrganisation : styles.userPersonal}`}
                            />
                          </AvatarImage>
                        </FishbowlUserAvatar>
                      </FishbowlAvatars>
                      <FishbowlLabels flowClass={`${styles.userDetails}`}>
                        <FishbowlUserLabel>
                          {`${firstname} ${lastname}`}
                        </FishbowlUserLabel>
                        <FishbowlOrganizationLabel>
                          {username}
                        </FishbowlOrganizationLabel>
                      </FishbowlLabels>
                    </FishbowlContainer>
                  </div>
                </div>
                <ul className={`${styles.userMenuLinkList}`}>
                  <hr/>
                  { isIpUser ? <>
                    {ipUserMenu}
                    <li><NamedLoginButton/></li>
                  </> : <>
                    {userMenu}
                    <hr/>
                    <li><LogoutButton/></li>
                  </>
                  }
                </ul>
              </div>
            </VerticalLayoutContent>
          </VerticalLayout>
        </SideModal>
      </div>
    </>
  );
};
