import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { serviceEnvironment } from "constants/config";
import { routeAccountSettings,
  routeRedirection
} from "../../constants/path.routing";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(`${serviceEnvironment}_token`);
      if (token) {
        headers.set("x-token", token);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getLoginFwdBtpLink: build.query({
      query: () =>
        `/auth/login?back=${encodeURI(`${window.location.origin}${routeRedirection}`)}`,
    }),
    getLoginFwdSettings: build.query({
      query: () =>
        `/auth/login?back=${encodeURI(
          `${window.location.origin}${routeAccountSettings}`
        )}`,
    }),
    getLoginUrl: build.query({
      query: () => `/auth/login?back=${encodeURI(window.location.href)}`,
    }),
    getReloginFwdSettings: build.query({
      query: () =>
        `/auth/relogin?back=${encodeURI(
          `${window.location.origin}${routeAccountSettings}`
        )}`,
    }),
    getReloginUrl: build.query({
      query: () => `/auth/relogin?back=${encodeURI(window.location.href)}`,
    }),
    getLogoutUrl: build.query({
      query: () => "/auth/logout",
    }),
    getToken: build.query({
      query: () => "/auth/token",
    }),
    getCsrfToken: build.query({
      query: () => "/csrf-token",
    }),
    getUserinfo: build.query({
      query: () => "/auth/userinfo",
    }),
    tryLogin: build.query({
      query: () => "/auth/login?prompt=none",
    }),
  }),
});

export const { useGetLoginUrlQuery, useGetLogoutUrlQuery } = authApi;
